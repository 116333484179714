import { IConnectedTo } from '@workerbase/domain/common';
import { FinderResourceTypes } from '@workerbase/domain/finder';
import { IDatabaseActionItem } from '@workerbase/domain/rule/actions/DatabaseAction.interface';

export enum ConnectedToTaskSources {
  VARIABLE = 'variable',
  RESOURCE = 'resource',
}

/**
 * For the purpose of easier type inference for the UI only,
 * we use this type to represent a connected to resource with a connected database item.
 * This type is not used in the backend.
 *
 * @see {normalizeRuleActionTaskConnectedToGET} normalizes the RuleGET to this type.
 * @see {normalizeRuleActionTaskConnectedToPUT} normalizes the RulePUT to the RuleGET type.
 */
export interface ConnectedToWithDatabaseAction extends IConnectedTo<FinderResourceTypes> {
  databaseItem?: IDatabaseActionItem | null;
  source: ConnectedToTaskSources;
}
