interface PathResult<T> {
  <TSubKey extends keyof T>(key: TSubKey): PathResult<T[TSubKey]>;
  pathLevels: string[];
  dotNotation: string;
  key: string;
}

/**
 * Class contains utility functions that extend native typescript type checking capabilities
 * @deprecated Use `KeyPaths`, `KeyPathValue`, and `getValueByKeyPath` instead
 */
export class TS {
  public static property<T>() {
    function subpath<T, TKey extends keyof T>(parent: string[], key: TKey): PathResult<T[TKey]> {
      const newPath: any = [...parent, key];
      const x = (<TSubKey extends keyof T[TKey]>(subkey: TSubKey) =>
        subpath<T[TKey], TSubKey>(newPath, subkey)) as PathResult<T[TKey]>;
      x.pathLevels = newPath;
      x.dotNotation = x.pathLevels.join('.');
      x.key = x.pathLevels.join('.');
      return x;
    }

    return <TKey extends keyof T>(key: TKey) => subpath<T, TKey>([], key);
  }
}

export type RequiredField<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

/**
 * Creates type for a class to pass it as an argument to a function
 *
 * Example of usage:
 *
 * class User {
 *  name: string;
 * }
 *
 * class SchemaFactory {
 *  createForClass(target: ClassType<User>) {
 *    // some code here using target
 *  }
 * }
 *
 * const model = SchemaFactory.createForClass(User);
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface ClassType<T = any> extends Function {
  new (...args: any[]): T;
}

/**
 * @internal
 * Helper type to conditionally append a nested key to a parent key.
 * If the nested key `P` is an empty string, it returns `never`, excluding that path from the union.
 * Otherwise, it concatenates the parent key `K` and the nested key `P` with a dot separator.
 */
type AppendKey<K extends string | number, P extends string> = P extends '' ? never : `${K}.${P}`;

/**
 * Generates a union of all possible key paths of an object `T` up to a specified depth `D`,
 * including optional nested properties. The key paths are represented as dot-separated strings.
 *
 * @typeParam T - The object type to generate key paths from.
 * @typeParam D - The maximum depth to traverse (default is `10`).
 * @typeParam P - An internal parameter used for recursion (do not set this manually).
 * @returns A union of key path strings representing all possible paths in `T` up to depth `D`.
 *
 * @example
 * ```typescript
 * type User = {
 *   id?: number;
 *   name: string;
 *   address?: {
 *     street?: string;
 *     city: string;
 *   };
 * };
 *
 * type UserKeyPaths = KeyPaths<User>;
 * // 'id' | 'name' | 'address' | 'address.street' | 'address.city'
 * ```
 */
export type KeyPaths<T, D extends number = 5, P extends unknown[] = []> = P['length'] extends D
  ? never
  : T extends object
  ? {
      [K in keyof T]: K extends string | number
        ? NonNullable<T[K]> extends object
          ? NonNullable<T[K]> extends (...args: unknown[]) => unknown
            ? `${K}` // Do not recurse into functions
            : NonNullable<T[K]> extends unknown[]
            ? `${K}` // Do not recurse into arrays
            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore -- TS is unable to infer limit of recursion here
              `${K}` | AppendKey<K, KeyPaths<NonNullable<T[K]>, D, [...P, unknown]>>
          : `${K}`
        : never;
    }[keyof T]
  : never;

/**
 * Retrieves the type of the value at a given key path `P` within an object `T`.
 *
 * @typeParam T - The object type to retrieve the value from.
 * @typeParam P - The key path string, which may include dots to represent nested properties.
 * @returns The type of the value at the specified key path, or `never` if the path is invalid.
 *
 * @example
 * ```typescript
 * type User = {
 *   name: string;
 *   address: {
 *     city: string;
 *     zipCode: number;
 *   };
 * };
 *
 * type CityType = KeyPathValue<User, 'address.city'>; // string
 * type InvalidType = KeyPathValue<User, 'address.country'>; // never
 * ```
 */
export type KeyPathValue<T, P extends string, D extends number = 5, A extends unknown[] = []> = A['length'] extends D
  ? never
  : P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? KeyPathValue<T[K], Rest, D, [...A, unknown]>
    : never
  : P extends keyof T
  ? T[P]
  : never;

/**
 * Retrieves the value from an object `obj` at the specified key path `path`.
 *
 * @typeParam T - The object type to retrieve the value from.
 * @typeParam P - The key path string, which must be one of the valid key paths of `T`.
 * @param obj - The object to retrieve the value from.
 * @param path - The key path string specifying the location of the value.
 * @returns The value at the specified key path.
 *
 * @example
 * ```typescript
 * const user = {
 *   name: 'Alice',
 *   address: {
 *     city: 'Wonderland',
 *     zipCode: 12345,
 *   },
 * };
 *
 * const city = getValueByKeyPath(user, 'address.city'); // 'Wonderland'
 * const zip = getValueByKeyPath(user, 'address.zipCode'); // 12345
 * ```
 */
export const getValueByKeyPath = <
  T extends object,
  P extends KeyPaths<T, 5> & string, // Ensure P is recognized as string so split method can be used
>(
  obj: T,
  path: P,
): KeyPathValue<T, P> => {
  const keys = path.split('.');
  let result: unknown = obj;

  for (const key of keys) {
    if (result && typeof result === 'object') {
      result = (result as Record<string, unknown>)[key];
    } else {
      return undefined as KeyPathValue<T, P>;
    }
  }

  return result as KeyPathValue<T, P>;
};

/**
 * Does the same as NonNullable, but recursively (works for nested objects)
 */
export type NonNullableDeep<T> = {
  [P in keyof T]-?: NonNullableDeep<T[P]>;
};

/**
 * Makes all properties in T optional recursively.
 * This includes nested objects and arrays of objects.
 *
 * @typeParam T - The type to make all properties optional.
 * @returns A type with all properties (including nested ones) made optional.
 *
 * @example
 * ```typescript
 * interface User {
 *   id: number;
 *   name: string;
 *   address: {
 *     street: string;
 *     city: string;
 *     zipCode: number;
 *   };
 *   roles: Array<{ id: number; name: string }>;
 * }
 *
 * type PartialUser = DeepPartial<User>;
 *
 * // All properties (including nested ones) are now optional:
 * const user: PartialUser = {
 *   name: 'Alice',
 *   address: { city: 'Wonderland' } // street and zipCode are optional
 * };
 * ```
 */
export type DeepPartial<T> = T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends Date
  ? T
  : T extends object
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : T;

/**
 * Checks if a value is a valid enum value.
 * @param enumObj - The enum object to check against.
 * @param value - The value to check.
 * @returns True if the value is a valid enum value, false otherwise.
 */
export const isValidEnumValue = <T extends Record<string, string>>(enumObj: T, value: string): value is T[keyof T] =>
  Object.values(enumObj).includes(value);
